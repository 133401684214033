import Flickity from "flickity-fade";

jQuery(function ($) {
	// Product slider
	let $slider = $(".slider-product");
	
	$slider.each(function () {
		let slider = new Flickity(this, {
			fade: true,
			lazyLoad: false,
			imagesLoaded: true,
			pageDots: false,
			wrapAround: true,
			setGallerySize: false,
			//autoPlay: 4000,
			arrowShape: {
				x0: 10,
				x1: 60,
				y1: 50,
				x2: 60,
				y2: 40,
				x3: 20,
			},
			//adaptiveHeight: true,
			//setGallerySize: false
		});
	});
});
