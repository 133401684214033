import Flickity from "flickity-fade";

jQuery(function ($) {
	let $slider = $(".slider-featured-products");

	$slider.each(function () {
		let $dis = $(this);
		let count = $dis.find(".slide").length > 2;
		let arrows = window.innerWidth < 768 ? true : count;

		let slider = new Flickity(this, {
			//fade: true,
			lazyLoad: 2,
			imagesLoaded: true,
			pageDots: false,
			wrapAround: true,
			prevNextButtons: arrows,
			cellAlign: "left",
			//autoPlay: 4000,
			arrowShape: {
				x0: 10,
				x1: 60,
				y1: 50,
				x2: 60,
				y2: 40,
				x3: 20,
			},
		});

		slider.on("settle", function (event, index) {
			$dis.removeClass("is-changing");
		});
		slider.on("change", function (event, index) {
			$dis.addClass("is-changing");
			setTimeout(function () {
				$dis.removeClass("is-changing");
			}, 500);
		});
	});
});
