jQuery(function ($) {
	let $item = $(".bg-list .item");

	$item.on("mouseenter mouseleave", function (e) {
		let $dis = $(this);
		let id = $dis.data("id");
		let $bgContainer = $dis.parents(".block").find(".container-bg");
		let $items = $bgContainer.find(".bg-item");

		let $bg = $bgContainer.find(".bg-" + id);

		if (e.type == "mouseenter") {
			$items.removeClass("is-active");
			$bg.addClass("is-active");
		} else {
			$items.removeClass("is-active");
		}
	});
});
