import Flickity from "flickity-fade";

jQuery(function ($) {
	let $slider = $(".slider-image-text");

	$slider.each(function () {
		// let $prev = $(this).next(".slider-featured-nav").find(".previous");
		// let $next = $(this).next(".slider-featured-nav").find(".next");
		let autoplay = $(this).hasClass("has-autoplay") ? 5000 : false;
		let arrows = $(this).hasClass("has-arrows");
		let slider = new Flickity(this, {
			fade: true,
			lazyLoad: 2,
			imagesLoaded: true,
			pageDots: true,
			wrapAround: true,
			autoPlay: autoplay,
			prevNextButtons: arrows,
			dragThreshold: 1,
			arrowShape: {
				x0: 10,
				x1: 60,
				y1: 50,
				x2: 60,
				y2: 40,
				x3: 20,
			},
			//adaptiveHeight: true,
			//setGallerySize: false
		});

		// $prev.on("click", function () {
		// 	slider.previous();
		// });
		// $next.on("click", function () {
		// 	slider.next();
		// });
	});

	// Scroll Y fix on ios
	// https://github.com/metafizzy/flickity/issues/740#issuecomment-485562201
	var tapArea, startX;
	tapArea = document.querySelectorAll(".slider-image-text");
	startX = 0;
	for (var item of tapArea) {
		item.ontouchstart = function (e) {
			startX = e.touches[0].clientX;
		};
		item.ontouchmove = function (e) {
			if (Math.abs(e.touches[0].clientX - startX) > 5 && e.cancelable) {
				e.preventDefault();
			}
		};
	}
});
