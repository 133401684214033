import Flickity from "flickity-fade";

jQuery(function ($) {
	let $slider = $(".slider-featured-designs");
	$slider.each(function () {
		let $dis = $(this);
		let slider = new Flickity(this, {
			//fade: true,
			pageDots: false,
			wrapAround: !$slider.hasClass("less-or-2"),
			//wrapAround: false,
			//contain: true,
			cellAlign: "center",
			//autoPlay: 4000,
			arrowShape: {
				x0: 10,
				x1: 60,
				y1: 50,
				x2: 60,
				y2: 40,
				x3: 20,
			},
		});
		let $images = $dis.prev(".featured-designs-images");
		slider.on("change", function (index) {
			let $img = $images.find(".img-" + index);
			if ($img.length) {
				$images.find(".is-active").removeClass("is-active");
				$img.addClass("is-active");
			}
		});
	});
});
