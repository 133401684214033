import Flickity from "flickity-fade";

jQuery(function ($) {
	let $slider = $(".slider-testimonials");

	$slider.each(function () {
		let slider = new Flickity(this, {
			fade: true,
			lazyLoad: 2,
			imagesLoaded: true,
			pageDots: false,
			wrapAround: true,
			autoPlay: 6000,
			arrowShape: {
				x0: 10,
				x1: 60,
				y1: 50,
				x2: 60,
				y2: 40,
				x3: 20,
			},
			//adaptiveHeight: true,
			//setGallerySize: false
		});
	});
});
