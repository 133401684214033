import Motus from "motus";
jQuery(function ($) {
    let w =
        typeof window.visualViewport !== "undefined"
            ? window.visualViewport.width
            : window.innerWidth;
    if (w >= 768) {
        let $statementImages = $(
            ".block-statement .image:not(.no-parallax) > *, .is-parallax > *"
        );
        $statementImages.each(function () {
            let $dis = $(this);
            let amount = $dis.parent(".is-parallax").hasClass("parallax-sm")
                ? "50"
                : "100";
            let statementParallax = new Motus.Animation({
                $el: $dis[0],
                keyframes: {
                    0: {
                        scale: [1.35],
                        translateY: amount + "px",
                    },
                    100: {
                        scale: [1.35],
                        translateY: -amount + "px",
                    },
                },
            });
            Motus.addAnimation(statementParallax);
        });
    }
});