jQuery(function ($) {
	/* -------------------------------------------------------------------------- */
	/*                                BLOCK: Accordion                            */
	/* -------------------------------------------------------------------------- */
	const $accordions = $(".accordion-item");

	$accordions.each(function () {
		const $dis = $(this),
			$trigger = $dis.find(".trigger"),
			$content = $dis.find(".accordion-content");

		$trigger.on("click", toggleAccordion);

		function toggleAccordion(e) {
			// Si está abierto, cerramos
			if ($dis.hasClass("is-active")) {
				$dis.removeClass("is-active");
				$content.slideUp();
			} else {
				// Si está cerrado, cerramos el resto y luego abrimos
				$accordions
					.removeClass("is-active")
					.find(".accordion-content")
					.slideUp();
				$dis.toggleClass("is-active");

				if ($dis.hasClass("is-active")) {
					// Desplegar
					$content.slideDown(function () {
						// Scroll automático
						// let count =
						// 	$dis.offset().top - $(".header-main").innerHeight();
						// window.scrollTo({
						// 	top: count,
						// 	left: 0,
						// 	behavior: "smooth",
						// });
					});
				}
			}
		}
	});
});
