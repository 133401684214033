import Flickity from "flickity-fade";

jQuery(function ($) {
	let $slider = $(".slider-split");

	$slider.each(function () {
		// let $prev = $(this).next(".slider-featured-nav").find(".previous");
		// let $next = $(this).next(".slider-featured-nav").find(".next");
		let autoplay = $(this).hasClass("has-autoplay") ? 5000 : false;
		let arrows = $(this).hasClass("has-arrows");
		let slider = new Flickity(this, {
			//fade: true,
			lazyLoad: 2,
			imagesLoaded: true,
			pageDots: false,
			wrapAround: true,
			autoPlay: autoplay,
			prevNextButtons: arrows,
			arrowShape: {
				x0: 10,
				x1: 60,
				y1: 50,
				x2: 60,
				y2: 40,
				x3: 20,
			},
			selectedAttraction: 0.05,
			friction: 0.5,
			//adaptiveHeight: true,
			//setGallerySize: false,
		});

		/* -------------------------------------------------------------------------- */
		/*                                 Parallax FX                                */
		/*                      https://codepen.io/anon/pen/WORpwx                    */
		/* -------------------------------------------------------------------------- */

		let $imgs = document.getElementsByClassName("split-slide-bg");
		slider.on("scroll", function () {
			slider.slides.forEach(function (slide, i) {
				var img = $imgs[i],
					x = 0;

				if (0 === i) {
					x =
						Math.abs(slider.x) > slider.slidesWidth
							? slider.slidesWidth +
							  slider.x +
							  slider.slides[slider.slides.length - 1]
									.outerWidth +
							  slide.target
							: slide.target + slider.x;
				} else if (i === slider.slides.length - 1) {
					x =
						Math.abs(slider.x) + slider.slides[i].outerWidth <
						slider.slidesWidth
							? slide.target -
							  slider.slidesWidth +
							  slider.x -
							  slider.slides[i].outerWidth
							: slide.target + slider.x;
				} else {
					x = slide.target + slider.x;
				}
				img.style.backgroundPosition = (x * -1) / 3 + "px";
			});
		});

		// $prev.on("click", function () {
		// 	slider.previous();
		// });
		// $next.on("click", function () {
		// 	slider.next();
		// });
	});
});
