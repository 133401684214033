import Flickity from "flickity-fade";

jQuery(function ($) {
	let $slider = $(".slider-featured-destinations");

	$slider.each(function () {
		let $prev = $(this).next(".slider-featured-nav").find(".previous");
		let $next = $(this).next(".slider-featured-nav").find(".next");
		let slider = new Flickity(this, {
			fade: false,
			lazyLoad: 2,
			imagesLoaded: true,
			pageDots: false,
			wrapAround: true,
			prevNextButtons: false,
			//autoPlay: 4000,
			arrowShape: {
				x0: 10,
				x1: 60,
				y1: 50,
				x2: 60,
				y2: 40,
				x3: 20,
			},
			//adaptiveHeight: true,
			//setGallerySize: false
		});

		$prev.on("click", function () {
			slider.previous();
		});
		$next.on("click", function () {
			slider.next();
		});
	});
});
